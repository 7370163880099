// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue, IconButton, Input } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React, { useEffect, useState } from "react";
import { constants } from "Constants";
import axios from "axios";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export default function Conversion(props) {
  const { user, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [loadingPieChart, setLoadingPieChart] = useState(true);
  const [data, setData] = useState([])
  const [pieChartData, setPieChartData] = useState([])

  const [year, setYear] = useState(new Date().getFullYear());

  const getPieChartData = async () => {
    setLoadingPieChart(true);
    try {
        const response = await axios.get(`${constants.url}bookings/piechart/${year}/${user.id}`);
        if (response.status === 200) {
            setData(response.data.data);
            setLoadingPieChart(false);
            // piechartdata in integer array format
            const piechartdata = [response.data.data.freeDays, response.data.data.reservedDays]
            setPieChartData(piechartdata)
        }
    } catch (error) {
      setLoadingPieChart(false);
        console.log(error);
    }
};

useEffect(() => {
  getPieChartData();
}, [user.id,year]);

  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          Total en días
        </Text>
        {/* input with icon prev and next with year */}
        <IconButton
          aria-label='Prev'
          icon={<BsChevronLeft />}
          size='sm'
          onClick={() => setYear(year - 1)}
        />
        <Input w='80px' value={year} readOnly />
        <IconButton
          aria-label='Next'
          icon={<BsChevronRight />}
          size='sm'
          onClick={() => setYear(year + 1)}
        />

      </Flex>

      {!loadingPieChart && <PieChart
        h='100%'
        w='100%'
        chartData={pieChartData}
        chartOptions={pieChartOptions}
      />}
      <Card
        bg={cardColor}
        flexDirection='row'
        boxShadow={cardShadow}
        w='100%'
        p='15px'
        px='20px'
        mt='15px'
        mx='auto'>
        <Flex direction='column' py='5px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#bf5048' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              Reservados
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {data.reservedDays}
          </Text>
        </Flex>
        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='#4caf50' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              mb='5px'>
              Libres
            </Text>
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {data.freeDays}
          </Text>
        </Flex>
      </Card>
    </Card>
  );
}
