import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { constants } from "Constants";
import { YearCalendar } from "components/calendar/YearCalendar";
import { UpdateDataContext } from "contexts/UpdateDataContext";

export const CalendarPage = () => {
  const { pathname } = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));

  const [bookings, setBookings] = useState({
    data: [],
    limit: pathname === "/admin/calendario" ? 999 : 5,
    page: 1,
    totalDocs: 0,
    totalPages: 0,
    filters: {
      search: "",
      status: "",
      show_past_bookings: true,
    },
  });

  const [events, setEvents] = useState([]);
  const [updateData, setUpdateData] = useContext(UpdateDataContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllBookings = async () => {
      setLoading(true);
      setUpdateData(false);
      try {
        let url = `${constants.url}bookings/user/${user.id}?page=${bookings.page}&limit=${bookings.limit}&show_past_bookings=${bookings.filters.show_past_bookings}`;

        if (bookings.filters.search) {
          url += `&search=${bookings.filters.search}`;
        }

        if (bookings.filters.status) {
          url += `&status=${bookings.filters.status}`;
        }

        if (bookings.filters.year) {
          url += `&year=${bookings.filters.year}`;
        }

        const response = await axios.get(url);

        if (response.status === 200) {
          setBookings({
            ...bookings,
            data: response.data.bookings.docs,
            limit: response.data.bookings.limit,
            page: response.data.bookings.page,
            totalDocs: response.data.bookings.totalDocs,
            totalPages: response.data.bookings.totalPages,
            filters: {
              search: response.data.filters.search,
              status: response.data.filters.status,
              year: null,
              show_past_bookings: response.data.filters.show_past_bookings,
            },
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getAllBookings();
  }, []);

  useEffect(() => {
    const generatedColors = new Set();

    const randomColor = () => {
      let color;
      do {
        color = Math.floor(Math.random() * 16777215).toString(16);
      } while (generatedColors.has(color));
      generatedColors.add(color);
      return `#${color}`;
    };

    const eventsParse =
      bookings.data &&
      bookings.data.map((item) => ({
        _id: item._id,
        id: item.id,
        name_of_guest: item.name_of_guest,
        randomColor: randomColor(),
        date_arrival: item.date_arrival ? new Date(item.date_arrival) : null,
        date_departure: item.date_departure
          ? new Date(item.date_departure)
          : null,
        title: " ",
        color: randomColor(),
        adults: item.adults,
        children: item.children,
        babies: item.babies,
        price: item.price,
      }));
    setEvents(eventsParse);
  }, [bookings]);

  const textColor = useColorModeValue("gray.500", "gray.200");

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      style={{ overflowY: "hidden" }}
    >
      {!loading ? (
        <YearCalendar
          events={events}
          bookings={bookings}
          setUpdateData={setUpdateData}
          setBookings={setBookings}
          setLoading={setLoading}
        />
      ) : (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Text fontSize="2xl" color={textColor}>
            Cargando...
          </Text>
          <Spinner ml={3} />
        </Flex>
      )}
    </Box>
  );
};
