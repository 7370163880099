// Chakra Imports
import {
	Avatar,
	Badge,
	Box,
	Button,
	Flex,
	Icon,
	IconButton,
	Image,
	Input,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	SimpleGrid,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { FaBell, FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
import { ThemeEditor } from './ThemeEditor';
import { useNavigate } from 'react-router-dom';
import { RiUserAddLine } from 'react-icons/ri';
import axios from 'axios';
import { constants } from 'Constants';
import Alerts from 'components/Alerts/Alert';
import { UpdateDataContext } from 'contexts/UpdateDataContext';
import moment from 'moment';
import { ModalEditCustomer } from 'views/admin/customers/components/ModalEditCustomer';
import { ModalEditBooking } from 'views/admin/bookings/components/ModalEditBooking';
import { ModalAddPhone } from 'views/admin/bookings/components/ModalAddPhone';
import { ModalSendMessage } from 'views/admin/bookings/components/ModalSendMessage';

// get version package.json
const { version } = require('./../../../package.json');
export default function HeaderLinks(props) {

	const userLogged = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
	// concat userLogged.name and userLogged.surname
	const userFullName = userLogged?.name + ' ' + userLogged?.surname;
	const [updateData, setUpdateData] = useContext(UpdateDataContext);

	// random color for avatar
	const randomColor = Math.floor(Math.random() * 16777215).toString(16);
	const avatarColor = '#' + randomColor;

	const { secondary } = props;
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

	// log out 
	const handleLogout = () => {
		localStorage.removeItem('user');
		sessionStorage.removeItem('token');
		window.location.href = '#/auth/login';

	};

	const navigate = useNavigate();
	const toProfile = () => {
		navigate('/admin/perfil');
	};

	const [alert, setAlert] = useState({
		show: false,
		status: "",
		message: "",
	})


	const [notifications, setNotifications] = useState([])
	const [customer, setCustomer] = useState({})
	const [showModalEditCustomer, setShowModalEditCustomer] = useState(false)
	const [showModalSendWhatsapp, setShowModalSendWhatsapp] = useState(false)

	const handleShowModalEditCustomer = (customer) => {
		setCustomer(customer)
		setShowModalEditCustomer(true)
	}

	const handleSendWhatsapp = (customer) => {
		setShowModalSendWhatsapp(true)
		setCustomer(customer)

	}


	
	const getNotifications = async () => {
		try {
			const response = await axios.get(`${constants.url}bookings/notifications/${userLogged.id}`);
			setNotifications(response.data.notifications)
		} catch (error) {
			console.log('Error de inicio de sesión:', error);
		}
	}

	React.useEffect(() => {
		getNotifications()
	}, [updateData])


	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			borderRadius="30px"
			boxShadow={shadow}>

			<SidebarResponsive routes={routes} />
			<Menu>
				<MenuButton p="0px">
				{/* <Box position="relative">
								<Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
								{notifications.length > 0 && (
								<Badge
									position="absolute"
									top="-2px"
									right="-5px"
									borderRadius="full"
									fontSize="10px"
									colorScheme="red"
								>
									{notifications.length}
								</Badge>
								)}
							</Box>
						</MenuButton>
					</Box> */}
					<Box position="relative">
						<MenuButton p="0px">
						<Flex
							bg={notifications.length > 0 ? 'red.300' : 'transparent'}
							borderRadius="30px"
							ms={notifications.length > 0 ? '-6px' : '0px'}
							p={notifications.length > 0 ? '6px' : '0px'}
							align={notifications.length > 0 ? 'center' : 'unset'}
							me={notifications.length > 0 ? '10px' : 'unset'}
							>
							<Flex align="center" justify="center" bg={ethBg} h="29px" w="29px" borderRadius="30px" me="7px">
								<Icon color={ethColor} w="18px" h="18px" as={MdNotificationsNone} />
							</Flex>
							{notifications.length > 0 && (
							<Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
								{notifications.length}
							</Text>
							)}
					</Flex>
						</MenuButton>
					</Box>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="20px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					me={{ base: '30px', md: 'unset' }}
					minW={{ base: 'unset', md: '400px', xl: '450px' }}
					maxW={{ base: '360px', md: 'unset' }}>
					<Flex jusitfy="space-between" w="100%" mb="20px">
						<Text fontSize="md" fontWeight="600" color={textColor}>
							Notificaciones ({notifications.length})
						</Text>
						{/* <Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
							Marcar todo leído
						</Text> */}
					</Flex>
					{/* <ModalEditBooking
						open={showModalEditCustomer} 
						setOpen={setShowModalEditCustomer}
						booking={customer} 
						setUpdateData={setUpdateData} 
						setAlert={setAlert}
					/> */}
					<ModalAddPhone
						open={showModalEditCustomer}	
						setOpen={setShowModalEditCustomer}
						booking={customer}
						setUpdateData={setUpdateData}
						setAlert={setAlert}
					/>
					<ModalSendMessage
						open={showModalSendWhatsapp}
						setOpen={setShowModalSendWhatsapp}
						booking={customer}
						setUpdateData={setUpdateData}
						setAlert={setAlert}
					/>

					<Flex flexDirection="column">
					{
						notifications && notifications.map((notification, index) => {

							const today = moment();
							const dateOfEntry = moment(notification.date_arrival);
							const daysLeft = dateOfEntry.diff(today, 'days');

							return(
								<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} 
									px="0" borderRadius="8px" mb="10px" key={index} 
									onClick={() => {
										notification.phone ?
										handleSendWhatsapp(notification) :
										handleShowModalEditCustomer(notification)
									}}
									>
									<ItemContent info={
										`Reserva de ${notification.id} de ${notification.name_of_guest}`
									} 
									message={
										`Quedan ${daysLeft} días para la fecha de entrada`
									} 
									linkText={
										notification.phone ? 'Enviar whatsapp' : 'Añadir teléfono'
									}
									btnWhatsapp={notification.phone ? true : false}
									/>
									
								</MenuItem>
						)
					})}
					
						{/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent info="Contenedor 2413" aName="Alicia" />
						</MenuItem> */}
					</Flex>
				</MenuList>
			</Menu>
			<Menu>
				<MenuButton p="0px">
					<Icon mt="6px" as={MdInfoOutline} color={navbarIcon} w="18px" h="18px" me="10px" />
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="20px"
					me={{ base: '30px', md: 'unset' }}
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					minW={{ base: 'unset' }}
					maxW={{ base: '360px', md: 'unset' }}>
					{/* <Image src={navImage} borderRadius="16px" mb="28px" /> */}
					<Flex flexDirection="column">
						<Link w="100%" href="#">
							<Button w="100%" h="44px" mb="10px" variant="brand">
								Novedades de la {version}
							</Button>
						</Link>
						{/* <Link w="100%" href="#">
							<Button
								w="100%"
								h="44px"
								mb="10px"
								border="1px solid"
								bg="transparent"
								borderColor={borderButton}>
								Documentación
							</Button>
						</Link> */}
					</Flex>
				</MenuList>
			</Menu>

			<ThemeEditor navbarIcon={navbarIcon} />

			<Menu>
				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						name={userFullName}
						bg={'linear-gradient(135deg, #667eea 0%, #764ba2 100%)'}
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; Hola, {userLogged.name}
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px"
							onClick={() => {
								toProfile();
							}}
							>
							<Text fontSize="sm">Perfil</Text>
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleLogout();
							}}
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px">
							<Text fontSize="sm">Cerrar sesion</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
			{alert.show && <Alerts open={alert.show} status={alert.status} message={alert.message} setAlert={setAlert}/>}
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
