import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.es';
import Card from 'components/card/Card';
import { useColorModeValue, Tooltip, IconButton, Flex, Text } from '@chakra-ui/react';
import tippy from "tippy.js";
import { FaDownload } from 'react-icons/fa';
import html2canvas from 'html2canvas';
import moment from 'moment';

export const YearCalendar = (props) => {
  const { events, bookings, setBookings, setUpdateData, setLoading, ...rest } = props;
  const [dataSource, setDataSource] = useState(events);
  const [tooltipData, setTooltipData] = useState(null);
  const calendarRef = useRef(null);

  const boxBg = useColorModeValue('white', 'secondaryGray.900');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  let tooltip = null;

  const handleDayEnter = (e) => {
    if (e.events.length > 0) {
      var content = "";

      for (var i in e.events) {
        content +=
          '<div class="event-tooltip-content">' +
          '<div class="event-name" style="color:"#000">' +
          e.events[i].name +
          "</div>" +
          '<div class="event-details">' +
          "Adultos: " + e.events[i].adults + "<br>" +
          "Niños: " + e.events[i].children + "<br>" +
          "Bebés: " + e.events[i].babies + "<br>" +
          "<hr /> " +
          "Precio: " + e.events[i].price + " €" + "<br>" +
          "</div>" +
          "</div>";
      }

      if (tooltip != null) {
        tooltip.destroy();
        tooltip = null;
      }

      tooltip = tippy(e.element, {
        placement: "right",
        content: content,
        animateFill: false,
        animation: "shift-away",
        arrow: true,
        allowHTML: true, // Agrega esta línea para permitir HTML en el contenido
      });
      tooltip.show();
    }
  };

  const handleDayLeave = () => {
    setTooltipData(null);
  };

  const handleDownloadCalendar = () => {
    setLoading(true);
    const calendarElement = calendarRef.current;

    if (!calendarElement) {
      return;
    }

    const calendarContainer = calendarElement.querySelector('.calendar');

    if (!calendarContainer) {
      return;
    }

    // Agrega una clase adicional al contenedor del calendario antes de descargar la imagen
    calendarContainer.classList.remove('calendar');
    calendarContainer.classList.add('downloading');

    // Genera la imagen usando html2canvas
    html2canvas(calendarContainer, { width: calendarContainer.offsetWidth }).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `calendario-reservas-${moment().format('DD-MMMM-YYYY')}.png`;
      link.click();

      // Elimina la clase adicional después de descargar la imagen
      calendarContainer.classList.remove('downloading');
      calendarContainer.classList.add('calendar');
    });

    setLoading(false);
  };

  const handleChangeYear = (e) => {
    setBookings({
      ...bookings,
      filters: {
        year: e.currentYear,
      },
    });
  };

  useEffect(() => {
    setDataSource(
      events &&
      events.map((e) => {
        return {
          id: e.id,
          name: e.name_of_guest,
          startDate: e.date_arrival,
          endDate: e.date_departure,
          adults: e.adults,
          children: e.children,
          babies: e.babies,
          price: e.price,
        };
      })
    );
  }, [events]);

  return (
    <div className='calendar' style={{ width: '100%' }} ref={calendarRef}>
      <Card align='center' direction='column' w='100%' bg={boxBg} p={5}>
        <Flex
          direction='row'
          justify='space-between'
          align='center'
          w='100%'
          mb={5}
        >
          <Text
            me='auto'
            color={textColor}
            fontSize='xl'
            fontWeight='700'
            lineHeight='100%'>
            Calendario de reservas
          </Text>
          <IconButton
            icon={<FaDownload />}
            aria-label='Download'
            variant='outline'
            mt={4}
            onClick={handleDownloadCalendar}
          />
        </Flex>

        {tooltipData && (
          <Tooltip label={tooltipData.events[0]?.name} isOpen={true} placement='top'>
            <div
              style={{
                position: 'absolute',
                top: tooltipData.clientY,
                left: tooltipData.clientX,
              }}
            >
              {/* Contenido del Tooltip */}

            </div>
          </Tooltip>
        )}
        <Calendar
          className='calendar-container'
          language='es'
          dataSource={dataSource}
          alwaysHalfDay={true}
          onDayEnter={(e) => handleDayEnter(e)}
          onDayLeave={() => handleDayLeave()}
          onYearChanged={(e) => handleChangeYear(e)}
          style='background'
        />
      </Card>
    </div>
  );
};
