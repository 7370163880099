// chakra imports
import { Icon, Flex, Text, useColorModeValue, IconButton } from "@chakra-ui/react";
import { MdUpgrade } from "react-icons/md";
import { FiAlertTriangle } from "react-icons/fi";
import React from "react";
import { FaPhone, FaWhatsapp } from "react-icons/fa";

export function ItemContent(props) {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <>
      <Flex
        onClick={props.onClick}
        justify='center'
        align='center'
        borderRadius='16px'
        minH={{ base: "60px", md: "70px" }}
        h={{ base: "60px", md: "70px" }}
        minW={{ base: "60px", md: "70px" }}
        w={{ base: "60px", md: "70px" }}
        me='14px'
        bg='linear-gradient(135deg, #822727 0%, #E31A1A 100%)'>
        <Icon as={FiAlertTriangle} color='white' w={8} h={14} />
      </Flex>
      <Flex flexDirection='column'>
        <Text
          mb='5px'
          fontWeight='bold'
          color={textColor}
          fontSize={{ base: "md", md: "md" }}>
          {props.info}
        </Text>
        <Flex alignItems='center'>
          <Text
            fontSize={{ base: "sm", md: "sm" }}
            lineHeight='100%'
            color={textColor}>
            {props.message}
          </Text>
          <br />
        </Flex>
        <Flex
          alignItems='center'
          mt='5px'
          mb='5px'
          color={textColor}
          fontSize={{ base: "sm", md: "sm" }}>
          {props.btnWhatsapp ? 
          <IconButton
            onClick={props.onClick}
            variant='ghost'
            colorScheme='green'
            aria-label='Call Segun'
            size='sm'
            icon={<FaWhatsapp />}
          /> :
          <IconButton
            onClick={props.onClick}
            variant='ghost'
            colorScheme='cyan'
            aria-label='Call Segun'
            size='sm'
            icon={<FaPhone />}
          />}
          <Text
            fontSize={{ base: "sm", md: "sm" }}
            lineHeight='100%'
            color={textColor}
            fontWeight='bold'>
            {props.linkText}
          </Text>
          </Flex>
      </Flex>
    </>
  );
}
