import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdPerson,
  MdLock,
  MdCalendarMonth,
} from "react-icons/md";


import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import SignInCentered from "views/auth/signIn";
import { CalendarPage } from "views/admin/calendar";
import { Bookings } from "views/admin/bookings";
import { BsBookmark } from "react-icons/bs";
import { AiOutlineBarChart } from "react-icons/ai";
import Marketplace from "views/admin/marketplace";



const routes = [
  
  {
    name: "Inicio",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Calendario",
    layout: "/admin",
    path: "/calendario",
    icon: <Icon as={MdCalendarMonth} width='20px' height='20px' color='inherit' />,
    component: CalendarPage,
  },
  {
    name: "Reservas",
    layout: "/admin",
    path: "/reservas",
    icon: <Icon as={BsBookmark} width='20px' height='20px' color='inherit' />,
    component: Bookings
  },
  {
    name: "Estadisticas",
    layout: "/admin",
    path: "/estadisticas",
    icon: <Icon as={AiOutlineBarChart} width='20px' height='20px' color='inherit' />,
    component: Marketplace
  },
  {
    name: "Perfil",
    layout: "/admin",
    path: "/perfil",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    secondary: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/login",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    secondary: true,
  }
];

export default routes;
