import React, { useState, useEffect } from 'react';
import { Alert, AlertIcon } from '@chakra-ui/react';

const Alerts = ({ open,setAlert, status, message }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      setAlert({
        show: false,
        status: "",
        message: "",
        });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {show && (
        <Alert position="fixed" bottom={4} right={4} maxWidth={400}
          status={status}
          motionpreset="slideInRight"
          onClose={() => setShow(false)}
          zIndex={9999}
        >
          <AlertIcon />
          {message}
        </Alert>
      )}
    </>
  );
};

export default Alerts;

