// Chakra imports
import {
  Box,
  Input,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  IconButton
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import BarChart from "components/charts/BarChart";
import React, { useState, useEffect } from "react";
import { barChartDataConsumption, barChartOptionsConsumption } from "variables/charts";
import { MdBarChart } from "react-icons/md";
import axios from "axios";
import { constants } from "Constants";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export default function WeeklyRevenue(props) {
  const { user, bookings,updateData, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const [dataChart, setDataChart] = useState(null);
  const [monthPrices, setMonthPrices] = useState([]);
  const [allPrices, setAllPrices] = useState()
  const [totalBookings, setTotalBookings] = useState()
  const [categories, setCategories] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  const getSumOfBookins = async () => {
    setDataChart(null)
    try {
      const response = await axios.get(
        `${constants.url}bookings/user/${user.id}/${year}`
      );
      if (response.status === 200) {
        const prices = response.data.months.map((data) => data.price);
        const categories = response.data.months.map((data) => data.month);
        setCategories(categories);
        setMonthPrices(prices);

        setAllPrices(response.data.total_price);
        setTotalBookings(response.data.total_bookings);
        setDataChart(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSumOfBookins();
  }, [year]);

  const chart = {
    series: [
      {
        name: "Ganancias",
        data: monthPrices,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + " €";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: [textColor],
        },
      },
      xaxis: {
        categories: categories,
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
          offsetY: -35,

        },
      },
      colors: ["#4caf50", "#ff686b", "#E1E9F8"],
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return (
              `<Text color=${textColor} fontSize="sm">` + 
                val + '€' + 
             ' </Text>'
            )
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };

  return (
    <Card align="center" direction="column" w="100%" {...rest}>
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Ganancias anuales
        </Text>
        <Flex align="center">
          <IconButton
            size="sm"
            fontSize="xl"
            aria-label="Bar Chart"
            variant="ghost"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            icon={<Icon as={BsChevronLeft} />}
            onClick={() => setYear(year - 1)}
          />

          <Input
            type="number"
            value={year}
            color={textColor}
            disabled
            onChange={(e) => setYear(e.target.value)}
            w="100px"
            mx="10px"
          />

          <IconButton
            size="sm"
            fontSize="xl"
            aria-label="Bar Chart"
            variant="ghost"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            icon={<Icon as={BsChevronRight} />}
            onClick={() => setYear(year + 1)}
          />
        </Flex>
      </Flex>

      <Box h="240px" mt="auto">
        {dataChart && (
          <BarChart
            chartData={chart.series}
            chartOptions={chart.options}
          />
        )}
      </Box>

      <Text color={textColor}>
        En {year} has generado un total de <b>{allPrices} €</b> con <b>{totalBookings}</b> reservas.
      </Text>

    </Card>
  );
}