
// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
import Upload from "views/admin/profile/components/Upload";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React, { useEffect, useState } from "react";
import { constants } from "Constants";
import axios from "axios";
import Alerts from "components/Alerts/Alert";

export default function Overview() {

  const user = JSON.parse(localStorage.getItem("user"))
  const [email_containers_timed_out, setEmail_containers_timed_out] = useState(user.email_containers_timed_out)

  const [alert, setAlert] = useState({
    show: false,
    status: "",
    message: "",
  })

  const editUser = async () => {
    setAlert({
      show: false,
      status: "",
      message: "",
    })

    try {
      const response = await axios.put(`${constants.url}user/edit/${user.id}`, {
        email_containers_timed_out
      });
      if (response.data.ok) { 
        // modificar el local storage
        localStorage.setItem("user", JSON.stringify(response.data.user))
        setAlert({
          show: true,
          status: "success",
          message: "Usuario editado correctamente",
        })
      }
    } catch (error) {
      // Manejo de errores
      console.log("Error al editar el usuario:", error);
      setAlert({
        show: true,
        status: "error",
        message: "Error al editar el usuario",
      })
    }
  };


      

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "repeat(3, 1fr)",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name={user.name}
          job={user.surname}
          // posts='17'
          // followers='9.7k'
          // following='274'
        />
        <Notifications
          used={25.6}
          total={50}
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          user={user}
          email_containers_timed_out={email_containers_timed_out}
          setEmail_containers_timed_out={setEmail_containers_timed_out}
          editUser={editUser}
        />
        {/* <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          used={25.6}
          total={50}
        />
        <Upload
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "1 / 3 / 2 / 4",
          }}
          minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
          pe='20px'
          pb={{ base: "100px", lg: "20px" }}
        /> */}
      </Grid>
      {/* <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Projects
          gridArea='1 / 2 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
        />
        <General
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH='365px'
          pe='20px'
        />
        
      </Grid> */}
      {alert.show && <Alerts open={alert.show} setAlert={setAlert} status={alert.status} message={alert.message} />}

    </Box>
  );
}
