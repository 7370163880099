import React, { useEffect, useState } from "react";
import {
  useColorModeValue,
  Modal,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import { constants } from "Constants";

export const ModalCreateBooking = (props) => {
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

  const { user, open, setOpen, setAlert, setUpdateData } = props;
  const onClose = () => setOpen(false);

  const [newBooking, setNewBooking] = useState({
    id_user: user.id,
    id: "",
    date_booking: new Date(),
    date_arrival: "",
    date_departure: "",
    status: "",
    name_of_guest: "",
    adults: "",
    children: "",
    babies: "",
    house: "Finca los Acebuches",
    price: "",
  });

  useEffect(() => {
    setNewBooking({
      id_user: user.id,
    });
  }, [open]);

  const handleCreateBooking = async () => {
    setUpdateData(false);
    try {
      const response = await axios.post(
        `${constants.url}bookings/create`,
        newBooking
      );
      if (response.status === 200) {
        setAlert({
          show: true,
          status: "success",
          message: response.data.message,
        });
        setUpdateData(true);
        onClose();
      }
    } catch (error) {
      setAlert({
        show: true,
        status: "error",
        message: error.response.data.message,
      });
    }
  };

  return (
    <div>
      <Modal size="xl" isOpen={open} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Crear Reserva</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={2} spacing={4}>
              <FormControl id="id">
                <FormLabel>Localizador</FormLabel>
                <Input
                  type="text"
                  value={newBooking.id}
                  color={textColor}
                  onChange={(e) =>
                    setNewBooking({ ...newBooking, id: e.target.value })
                  }
                />
              </FormControl>
              {/* <FormControl id="date_booking">
                                <FormLabel>Fecha de Reserva</FormLabel>
                                <Input
                                    value={newBooking.date_booking}
                                    type="date"
                                    color={textColor}
                                    onChange={(e) =>
                                        setNewBooking({ ...newBooking, date_booking: e.target.value })
                                    }
                                />
                            </FormControl> */}
              <FormControl id="date_arrival">
                <FormLabel>Fecha de Llegada</FormLabel>
                <Input
                  value={newBooking.date_arrival}
                  type="date"
                  color={textColor}
                  onChange={(e) =>
                    setNewBooking({
                      ...newBooking,
                      date_arrival: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl id="date_departure">
                <FormLabel>Fecha de Salida</FormLabel>
                <Input
                  value={newBooking.date_departure}
                  type="date"
                  color={textColor}
                  onChange={(e) =>
                    setNewBooking({
                      ...newBooking,
                      date_departure: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl id="status">
                <FormLabel>Estado</FormLabel>
                <Select
                  value={newBooking.status}
                  color={textColor}
                  placeholder="Selecciona una opción"
                  onChange={(e) =>
                    setNewBooking({ ...newBooking, status: e.target.value })
                  }
                >
                  <option value="pendiente">Pendiente</option>
                  <option value="confirmada">Confirmada</option>
                  <option value="cancelada">Cancelada</option>
                </Select>
              </FormControl>
              {newBooking.status !== "pendiente" && (
                <FormControl id="name_of_guest">
                  <FormLabel>Nombre del huésped</FormLabel>
                  <Input
                    value={newBooking.name_of_guest}
                    type="text"
                    color={textColor}
                    onChange={(e) =>
                      setNewBooking({
                        ...newBooking,
                        name_of_guest: e.target.value,
                      })
                    }
                  />
                </FormControl>
              )}
              <FormControl id="adults">
                <FormLabel>Adultos</FormLabel>
                <Input
                  value={newBooking.adults}
                  type="number"
                  color={textColor}
                  onChange={(e) =>
                    setNewBooking({ ...newBooking, adults: e.target.value })
                  }
                />
              </FormControl>
              <FormControl id="children">
                <FormLabel>Niños</FormLabel>
                <Input
                  value={newBooking.children}
                  type="number"
                  color={textColor}
                  onChange={(e) =>
                    setNewBooking({ ...newBooking, children: e.target.value })
                  }
                />
              </FormControl>
              <FormControl id="babies">
                <FormLabel>Bebés</FormLabel>
                <Input
                  value={newBooking.babies}
                  type="number"
                  color={textColor}
                  onChange={(e) =>
                    setNewBooking({ ...newBooking, babies: e.target.value })
                  }
                />
              </FormControl>
              {/* <FormControl id="house">
                                <FormLabel>Casa</FormLabel>
                                <Input
                                    value={newBooking.house}
                                    type="text"
                                    color={textColor}
                                    onChange={(e) =>
                                        setNewBooking({ ...newBooking, house: e.target.value })
                                    }
                                />
                            </FormControl> */}
              <FormControl id="price">
                <FormLabel>Precio</FormLabel>
                <Input
                  value={newBooking.price}
                  type="number"
                  color={textColor}
                  onChange={(e) =>
                    setNewBooking({ ...newBooking, price: e.target.value })
                  }
                />
              </FormControl>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={
                newBooking.id === "" ||
                newBooking.date_arrival === "" ||
                newBooking.date_departure === "" ||
                newBooking.house === ""
              }
              onClick={() => {
                handleCreateBooking();
              }}
            >
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
