// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import Icons from "components/sidebar/components/Icons";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import { HSeparator } from "components/separator/Separator";

// FUNCTIONS

function SidebarContent(props) {
  const { routes, openSidebar } = props;
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      {
        openSidebar ?
          <Brand />
          :
          <Flex align='center' direction='column'>
            <h1 style={{fontSize: 45, fontWeight: 600}}>B</h1>
            <HSeparator mb='20px' />
          </Flex>
      }
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          { openSidebar ? 
            <Links routes={routes} />
            :
            <Icons routes={routes} />
          }
        </Box>
      </Stack>

      <Box
        ps='20px'
        pe={{ md: "16px", "2xl": "0px" }}
        mt='60px'
        mb='40px'
        borderRadius='30px'>
        {/* <SidebarCard /> */}
      </Box>
    </Flex>
  );
}

export default SidebarContent;
