import React, { useEffect, useState } from 'react'
import {
    Flex,
    Table,
    Card,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Icon,
    Select,
    Menu,
    MenuButton,
    IconButton,
    MenuItem,
    MenuList,
    Box,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Button,
    Spinner,
    HStack,
    Tag,
    TagLabel,
    TagCloseButton,
    Divider,
    Tooltip
} from "@chakra-ui/react";
import { TbDotsVertical } from "react-icons/tb";
import moment from 'moment/moment';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { ImExit, ImSpinner9 } from 'react-icons/im';
import Pagination from 'components/Pagination/Pagination';

import { EditIcon } from '@chakra-ui/icons';
import { BsTrash } from 'react-icons/bs';
import { ModalEditBooking } from 'views/admin/bookings/components/ModalEditBooking';
import { ModalDeleteBooking } from 'views/admin/bookings/components/ModalDeleteBooking';
import { CloseIcon } from '@chakra-ui/icons';
import { FiFilter } from 'react-icons/fi';
import { AiOutlineCalendar, AiOutlineCloudDownload, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export const BookingTable = (props) => {

    const { setAlert, bookings, setBookings, setUpdateData, actions, loading, ...rest } = props;
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const boxBg = useColorModeValue("white", "secondaryGray.900");
    const tableBg = useColorModeValue("green.300", "green.900");

    const [modalEditBooking, setModalEditBooking] = useState(false);
    const [modalDeleteBooking, setModalDeleteBooking] = useState(false);
    const [bookingSelected, setBookingSelected] = useState(null);

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [year, setYear] = useState(moment().format('YYYY'));
    const [showPastBookings, setShowPastBookings] = useState(false);

    const handleFilter = (e) => {
        setStatus(e.target.value);
    }

    const onPageChange = (page) => {
        setBookings({ ...bookings, page: page });
        setUpdateData(true);
    }

    const handleNextYear = () => {
        setYear(moment(year).add(1, 'year').format('YYYY'));
    }

    const handlePrevYear = () => {
        setYear(moment(year).subtract(1, 'year').format('YYYY'));
    }

    useEffect(() => {
        setBookings({
            ...bookings,
            filters: {
                ...bookings.filters,
                search: search,
                status: status,
                year: year,
                show_past_bookings: showPastBookings
            },
        });
    }, [search, status, year, showPastBookings]);

    return (
        <>
            <Card
                direction='column'
                w='100%'
                p='5'
                bg={boxBg}
                overflowX={{ sm: "scroll", lg: "hidden" }}>
                <Flex minWidth='max-content' alignItems='center' gap='2'>
                    <Text
                        me='auto'
                        color={textColor}
                        fontSize='xl'
                        fontWeight='700'
                        lineHeight='100%'>
                        Reservas
                    </Text>
                    <Box p='2'>
                        <Flex>
                            <InputGroup>
                                <Input
                                    placeholder='Busca por cliente o ID'
                                    autoComplete="off"
                                    color={textColor}
                                    border='1px solid'
                                    borderColor='gray.200'
                                    borderRadius='5px'
                                    mr={2}
                                    w='200px'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    _focus={{
                                        outline: "none",
                                        boxShadow: "none",
                                        borderColor: "primaryBlue.500",
                                    }}
                                />
                                {search &&
                                    <IconButton
                                        aria-label='close search'
                                        icon={<CloseIcon size='12px' />}
                                        size="xs"
                                        mt={2}
                                        position='relative'
                                        onClick={() => setSearch("")}
                                    />
                                }
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        size="xs"
                                        position='relative'
                                        mt={2}
                                        mr={2}
                                        icon={<FiFilter size='20px' />}
                                    >
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem value="" onClick={handleFilter}>
                                            Todos
                                        </MenuItem>
                                        <MenuItem value="confirmada" onClick={handleFilter}>
                                            Confirmada
                                        </MenuItem>
                                        <MenuItem value="pendiente" onClick={handleFilter}>
                                            Pendiente
                                        </MenuItem>
                                        <MenuItem value="cancelada" onClick={handleFilter}>
                                            Cancelada
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                                <Tooltip  label="Mostrar reservas pasadas">
                                    <IconButton
                                        aria-label='download'
                                        icon={
                                            showPastBookings ?
                                                <AiOutlineEyeInvisible size='20px' /> :
                                                <AiOutlineEye size='20px' /> 
                                        }
                                        size="xs"
                                        mt={2}
                                        mr={2}
                                        position='relative'
                                        onClick={() => setShowPastBookings(!showPastBookings)}
                                    />
                                </Tooltip>
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        size="xs"
                                        position='relative'
                                        mt={2}
                                        mr={2}
                                        icon={<AiOutlineCalendar size='20px' />}
                                    >
                                    </MenuButton>
                                    <MenuList>
                                        {/* button one year less */}
                                        <Button
                                            colorScheme="gray"
                                            variant="ghost"
                                            size="sm"
                                            onClick={handlePrevYear}                                >
                                            <Icon as={BsChevronLeft} mr={2} />
                                        </Button>

                                        <Input
                                            type='number'
                                            placeholder='Año'
                                            autoComplete="off"
                                            color={textColor}
                                            border='1px solid'
                                            borderColor='gray.200'
                                            borderRadius='5px'
                                            isDisabled
                                            mr={2}
                                            w='100px'
                                            value={year}
                                            onChange={(e) => setYear(e.target.value)}
                                            _focus={{
                                                outline: "none",
                                                boxShadow: "none",
                                                borderColor: "primaryBlue.500",
                                            }}
                                        />
                                        <Button
                                            colorScheme="gray"
                                            variant="ghost"
                                            size="sm"
                                            onClick={handleNextYear}
                                        >
                                            <Icon as={BsChevronRight} mr={2} />
                                        </Button>
                                    </MenuList>
                                </Menu>
                                {/* <Tooltip 
                            label="Se descargará un archivo .xlsx con los contenedores filtrados" 
                            aria-label="Descargar base de datos"
                        >
                            <IconButton
                                aria-label='Download database'
                                icon={<AiOutlineCloudDownload size='25px' />}
                                size="xs"
                                mt={2}
                                mr={2} 
                                position='relative'
                                onClick={() => exportTableToExcel('table-export', `contenedores-${moment().format("DD-MM-YYYY")}`)}
                            />
                        </Tooltip> */}
                            </InputGroup>
                        </Flex>
                        <Flex mt={2}>
                         {status !== "" && 
                        <HStack spacing={4} mr={2}>
                            <Tag
                                borderRadius='full'
                                variant='solid'
                                colorScheme={
                                    status === "confirmada" ? "green" : status === "pendiente" ? "yellow" : "red"
                                }
                            >
                                <TagLabel>
                                {status}
                                </TagLabel>
                                <TagCloseButton onClick={() => setStatus("")} />
                            </Tag>
                            </HStack>}
                            {year !== moment().format('YYYY') &&
                                <HStack spacing={4}>
                                <Tag
                                    borderRadius='full'
                                    variant='solid'
                                    colorScheme='gray'
                                >
                                    <TagLabel>
                                        {year}
                                    </TagLabel>
                                    <TagCloseButton onClick={() => setYear(moment().format('YYYY'))} />
                                </Tag>
                            </HStack>}
                        </Flex>
                    </Box>
                </Flex>

                <Table variant='simple' mb='24px' bg={boxBg}>
                    <Thead>
                        <Tr>
                            <Th>Localizador</Th>
                            <Th>Nombre</Th>
                            <Th>Fecha</Th>
                            <Th>Días</Th>
                            <Th>Estado</Th>
                            <Th>Adultos</Th>
                            <Th>Niños</Th>
                            <Th>Bebés</Th>
                            <Th>Alojamiento</Th>
                            <Th>Precio</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {bookings?.data?.length > 0 ? (
                            bookings.data.map((item, index) => {

                                {/* get days between arrival and departure */ }
                                const arrival = moment(item.date_arrival);
                                const departure = moment(item.date_departure);
                                const days = departure.diff(arrival, 'days');

                                {/* get days between arrival and today */ }
                                const today = moment();
                                const daysToArrival = departure.diff(today, 'days');

                                return (
                            
                                <Tr key={index} bg={daysToArrival < 0 && tableBg}>
                                    <Td color={textColor}>{item.id}</Td>
                                    <Td color={textColor}>{item.name_of_guest}</Td>
                                    <Td color={textColor}>
                                        {moment(item.date_arrival).format('DD/MM/YYYY')}
                                        <Divider 
                                            orientation="horizontal"
                                            borderColor={textColor}
                                            borderWidth='1px'
                                            w='80%'
                                            my={1}
                                        />
                                        {moment(item.date_departure).format('DD/MM/YYYY')}
                                    </Td>
                                    <Td color={textColor}>{days}</Td>
                                    <Td color={textColor}>
                                        <span style={{ textTransform: 'capitalize', marginRight: '4px' }}>{item.status}</span>
                                        <Icon
                                            as={item.status.toLowerCase() === 'confirmada' ? FaCheckCircle : item.status.toLowerCase() === 'pendiente' ? ImSpinner9 : FaTimesCircle}
                                            color={item.status.toLowerCase() === 'confirmada' ? 'green.500' : item.status.toLowerCase() === 'pendiente' ? 'yellow.500' : 'red.500'}
                                            w='18px'
                                            h='18px'
                                        />
                                    </Td>
                                    <Td color={textColor}>{item.adults}</Td>
                                    <Td color={textColor}>{item.children}</Td>
                                    <Td color={textColor}>{item.babies}</Td>
                                    <Td color={textColor}>{item.house}</Td>
                                    <Td color={textColor}>{item.price} €</Td>
                                    {actions && (
                                        <Td justifyContent='start'>
                                            <Flex>
                                                <Menu>
                                                    <MenuButton as={IconButton} aria-label='Options' icon={<TbDotsVertical />} variant='outline'>
                                                        <TbDotsVertical />
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem
                                                            icon={<EditIcon />}
                                                            color={textColor}
                                                            fontWeight='500'
                                                            fontSize='15px'
                                                            onClick={() => {
                                                                setBookingSelected(item);
                                                                setModalEditBooking(true);
                                                            }}
                                                        >
                                                            Editar
                                                        </MenuItem>
                                                        <MenuItem
                                                            icon={<BsTrash />}
                                                            color='red.500'
                                                            fontWeight='500'
                                                            fontSize='15px'
                                                            onClick={() => {
                                                                setBookingSelected(item);
                                                                setModalDeleteBooking(true);
                                                            }}
                                                        >
                                                            Eliminar
                                                        </MenuItem>
                                                    </MenuList>
                                                </Menu>
                                            </Flex>
                                        </Td>
                                    )}
                                </Tr>
                            )})
                        ) : !loading ? (
                            <Tr>
                                <Td colSpan='7' textAlign='center'>
                                    No hay reservas
                                </Td>
                            </Tr>
                        ) : (
                            <Tr>
                                <Td colSpan='7' textAlign='center'>
                                    <Spinner />
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>

            </Card>
            <Card justifyContent='space-between' alignItems='center' pb={3} w='100%' bg={boxBg}>
                <Flex mt={5}>
                    <Flex>
                        <Select value={bookings.limit} w='80px' marginRight='10px' mt='20px'
                            onChange={(e) => {
                                setBookings({ ...bookings, limit: parseInt(e.target.value) });
                                setUpdateData(true);
                            }}
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </Select>
                    </Flex>

                    <Pagination currentPage={bookings.page} totalPages={bookings.totalPages} onPageChange={onPageChange} />
                </Flex>
                <Text fontSize="12px" color="gray.500" mt={4}>
                    Mostrando {bookings.page} de {bookings.totalPages} páginas
                </Text>
            </Card>
            <ModalEditBooking open={modalEditBooking} setOpen={setModalEditBooking} booking={bookingSelected} setUpdateData={setUpdateData} setAlert={setAlert} />
            <ModalDeleteBooking open={modalDeleteBooking} setOpen={setModalDeleteBooking} booking={bookingSelected} setUpdateData={setUpdateData} setAlert={setAlert} />
        </>
    )
}
