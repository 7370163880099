import { Box, Card, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import axios from 'axios';
import { constants } from 'Constants';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';

export const AreaChart = (props) => {

    const { user } = props;
    const colorMode = localStorage.getItem("chakra-ui-color-mode");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textTooltip = useColorModeValue("gray", "white");
    const bgCard = useColorModeValue("white", "gray");

    const lightColor = useColorModeValue('#546E7A', '#FFFFFF');
    const darkColor = useColorModeValue('#F44336', '#FF0000');

    const [data, setData] = useState([])
    const [series, setSeries] = useState([])
    const [options, setOptions] = useState(null)

    const [loadingAreaChart, setLoadingAreaChart] = useState(true)


    const getAreaChartData = async () => {
        setLoadingAreaChart(true);
        try {
            const response = await axios.get(`${constants.url}bookings/areachart/${user.id}`);
            if (response.status === 200) {
                setData(response.data.data);
                setLoadingAreaChart(false);
            }
        } catch (error) {
            setLoadingAreaChart(false);
            console.log(error);
        }
    };

    useEffect(() => {
        getAreaChartData();
    }, [user.id]);

    useEffect(() => {
        // Configuración de la gráfica
        const alloptions = {
            chart: {
                type: 'area',
            },
            xaxis: {
                type: 'category',
                categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                labels: {
                    style: {
                        colors: textColor,
                    },
                }
            },
            legend: {
                labels: {
                    colors: textColor,
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return `${value} €`;
                    },
                    style: {
                        colors: textColor,
                    },
                },
            },
            tooltip: {
                theme: colorMode,

                y: {
                    formatter: function (value) {
                        return `${value} €`;
                    },
                },
            },
            colors: ['#EAE100', '#00A8B5', '#1D3557', '#457B9D', '#A8DADC', '#4caf50', '#FCA311', '#F48C06', '#E85D04', '#DC2F02', '#9D0208', '#6A040F'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                },
            },
        };

        // Procesamiento de los datos para ApexCharts
        const allseries = data && Object.keys(data).map((year) => {
            const yearData = data[year];
            const yearTotal = yearData.total;
            const yearMonths = yearData.months && yearData.months.map((month) => month.price);

            return {
                name: year,
                data: yearMonths,
            };
        });

        setOptions(alloptions);
        setSeries(allseries);
    }, [data]);

    return (
        <div>
            <Card w="100%">
                <Flex align="center" w="100%" px="15px" py="10px">
                    <Text
                        me="auto"
                        color={textColor}
                        fontSize="xl"
                        fontWeight="700"
                        lineHeight="100%"
                    >
                        Ingresos por mes en todos los años
                    </Text>
                </Flex>

                <Box h="350" mt="auto">
                    {
                        options && series && !loadingAreaChart ?
                            <ReactApexChart options={options} series={series} type="area" height={350} />
                            :
                            <Flex align="center" justify="center" h="100%">
                                <Text color={textColor} fontSize="xl" fontWeight="700" lineHeight="100%">
                                    Cargando...
                                </Text>
                            </Flex>
                    }

                </Box>
            </Card>
        </div>
    )
}
