// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { constants } from "Constants";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Route, Routes, useLocation, matchRoutes, matchPath } from "react-router-dom";
import routes from "routes.js";
import { UpdateDataContext } from "contexts/UpdateDataContext";
import moment from "moment";

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
	const [updateData, setUpdateData] = useContext(UpdateDataContext);
  const [loading, setLoading] = useState(false);

  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [toggleSidebarXL, setToggleSidebarXL] = useState(true);
  
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (!token || !user) {
      navigate("/auth/login");
    }
  }, [token, navigate]);

  const [bookings, setBookings] = useState({
    data: [],
    limit: pathname === "/admin/calendario" ? 999 : 5,
    page: 1,
    totalDocs: 0,
    totalPages: 0,
    filters: {
      search: "",
      status: "",
      year: moment().format("YYYY"),
      show_past_bookings: false,
    },
  });

  useEffect(() => {
    if (pathname === "/admin/bookings") {
      setBookings({
        ...bookings,
        limit: 5,
      });
    } else if (pathname === "/admin/calendario") {
      setBookings({
        ...bookings,
        limit: 999,
      });
    }
  }, [navigate, pathname]);


  const getAllBookings = async () => {
    setLoading(true);
    setUpdateData(false);
    try {
      let url = `${constants.url}bookings/user/${user.id}?page=${bookings.page}&limit=${bookings.limit}&show_past_bookings=${bookings.filters.show_past_bookings}`;
  
      if (bookings.filters.search) {
        url += `&search=${bookings.filters.search}`;
      }
  
      if (bookings.filters.status) {
        url += `&status=${bookings.filters.status}`;
      }
      
      if (bookings.filters.year) {
        url += `&year=${bookings.filters.year}`;
      }

      const response = await axios.get(url);
      if (response.status === 200) {
        setBookings({
          data: response.data.bookings.docs,
          limit: response.data.bookings.limit,
          page: response.data.bookings.page,
          totalDocs: response.data.bookings.totalDocs,
          totalPages: response.data.bookings.totalPages,
          filters: {
            search: response.data.filters.search,
            status: response.data.filters.status,
            year: response.data.filters.year,
            show_past_bookings: response.data.filters.show_past_bookings,
          }
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  useEffect(() => {
    getAllBookings();
  }, [updateData, bookings.page, bookings.limit, bookings.filters.search, bookings.filters.status, bookings.filters.year, bookings.filters.show_past_bookings]);



  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && token && user) {
        const Component = prop.component;
        return (
          <Route
            path={prop.path}
            key={key}
            element={
              <Component 
                bookings={bookings} 
                setBookings={setBookings}
                loading={loading}
              />}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}>
        <Sidebar 
          routes={routes} 
          display='none' 
          openSidebar={toggleSidebarXL}
          setOpenSidebar={setToggleSidebarXL}
          {...rest} />
        <Box
          float='right'
          minHeight='100vh'
          height='100%'
          overflow='auto'
          position='relative'
          maxHeight='100%'
          w={{ base: "100%", xl: toggleSidebarXL ? "calc( 100% - 290px )" : "calc( 100% - 70px )" }}
          maxWidth={{ base: "100%", xl: toggleSidebarXL ? "calc( 100% - 290px )" : "calc( 100% - 70px )"}}
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                // logoText={"Horizon UI Dashboard PRO"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                openSidebar={toggleSidebarXL}
                setOpenSidebar={setToggleSidebarXL}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx='auto'
              p={{ base: "20px", md: "30px" }}
              pe='20px'
              minH='100vh'
              pt='50px'>
              <Routes>
                {getRoutes(routes)}
                {/* <Navigate path='/admin' to='/admin/dashboard' /> */}
              </Routes>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
