import React, { useState, useEffect, useContext } from 'react'
import { Box } from '@chakra-ui/react';
import { BookingTable } from '../default/components/BookingTable'
import Alerts from 'components/Alerts/Alert';
import { UpdateDataContext } from 'contexts/UpdateDataContext';

export const Bookings = (props) => {
    
    const { bookings, setBookings } = props;
    const [updateData, setUpdateData] = useContext(UpdateDataContext);

    const [alert, setAlert] = useState({
        show: false,
        status: "",
        message: "",
      })
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <BookingTable 
            setAlert={setAlert}
            bookings={bookings}
            setBookings={setBookings}
            setUpdateData={setUpdateData}
            actions={true}
          />
        {alert.show && <Alerts open={alert.show} setAlert={setAlert} status={alert.status} message={alert.message} />}

    </Box>
  )
}
