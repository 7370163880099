
import {
  Avatar,
  Box,

  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import axios from "axios";
// Custom components

import { constants } from "Constants";
import React, { useEffect, useState } from "react";

import PieCard from "views/admin/default/components/PieCard";


import { AreaChart } from "./components/AreaChart";

export default function UserReports(props) {
  const { bookings, setBookings, loading } = props;

  const user = JSON.parse(localStorage.getItem("user"))
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");


  const [areaChartData, setAreaChartData] = useState([])

  // const getAreaChartData = async () => {
  //   setLoadingAreaChart(true)
  //   try {
  //     const response = await axios.get(
  //       `${constants.url}bookings/areachart/${user.id}`
  //     );
  //     if (response.status === 200) {
  //       setAreaChartData(response.data.data);
  //       setLoadingAreaChart(false)
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoadingAreaChart(false)
  //   }
  // };

  // useEffect(() => {
  //   getAreaChartData();
  // }, []);


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Earnings'
          value='$350.4'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Spend this month'
          value='$642.39'
        />
        <MiniStatistics growth='+23%' name='Sales' value='$574.34' />
        <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='New Tasks'
          value='154'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        />
      </SimpleGrid> */}

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
         <AreaChart bookings={bookings} user={user} />
        <WeeklyRevenue />
          <PieCard />  
      </SimpleGrid> */}
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <AreaChart bookings={bookings} user={user} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <PieCard bookings={bookings} user={user} />  
        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
}
