// Chakra imports
import { Button, Flex, Switch, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import SwitchField from "components/fields/SwitchField";
import Menu from "components/menu/MainMenu";

export default function Notifications(props) {
  const {user,email_containers_timed_out,setEmail_containers_timed_out,editUser, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const handleChangeSwitch = (e) => {
    setEmail_containers_timed_out(!email_containers_timed_out);
    user.email_containers_timed_out = !email_containers_timed_out;
  };
  return (
    <Card mb="20px" mt="40px" mx="auto" maxW="410px" {...rest}>
      <Flex align="center" w="100%" justify="space-between" mb="30px">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mb="4px"
        >
          Notificaciones
        </Text>
      </Flex>
      <Flex align="center" w="100%" justify="space-between" mb="30px">
        <Switch
          isChecked={email_containers_timed_out}
          variant="main"
          colorScheme="brandScheme"
          size="md"
          onChange={() => handleChangeSwitch()}
        />
        <Text color={textColorPrimary} fontSize="md" fontWeight="500">
          Avisos contenedores fuera de fecha
        </Text>
      </Flex>
      <Button 
        colorScheme="blue"
        size="md"
        onClick={() => editUser()}
      >
        Guardar
      </Button>
    </Card>
  );
}
