import React, { createContext, useState, useMemo } from 'react';

export const UpdateDataContext = createContext();

export const UpdateDataProvider = (props) => {
  const [updateData, setUpdateData] = useState(false);

  const contextValue = useMemo(() => [updateData, setUpdateData], [updateData, setUpdateData]);

  return (
    <UpdateDataContext.Provider value={contextValue}>
      {props.children}
    </UpdateDataContext.Provider>
  );
};
