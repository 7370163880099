import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Box,
  Flex,
  TableContainer,
  Grid,
  GridItem,
  Icon,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import MiniStatistics from "components/card/MiniStatistics";

import Alerts from "components/Alerts/Alert";
import axios from "axios";
import { constants } from "Constants";
import { AiOutlinePlus } from "react-icons/ai";
import { BookingTable } from "./components/BookingTable";
import { ModalCreateBooking } from "../modals/ModalCreateBooking";

import CheckTable from "views/admin/default/components/CheckTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";

import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import MiniCalendar from "components/calendar/MiniCalendar";

import { UpdateDataContext } from "contexts/UpdateDataContext";
import MonthCalendar from "components/calendar/MonthCalendar";
import { BsBookmark, BsBookmarkPlusFill } from "react-icons/bs";

export default function UserReports(props) {

  const { bookings, setBookings, loading } = props;
	const [updateData, setUpdateData] = useContext(UpdateDataContext);

  const user = JSON.parse(localStorage.getItem("user"))

  const [openModalCreateBooking, setOpenModalCreateBooking] = useState(false)


  const [alert, setAlert] = useState({
    show: false,
    status: "",
    message: "",
  })

  useEffect(() => {
    setBookings({ ...bookings, limit: 5 });
    setUpdateData(true);
  }, [])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          endContent={
            <Button
              w='56px'
              h='56px'
              bg="blue.500"
              color="white"
              variant='outline'
              borderRadius='50%'
              _hover={{bg: 'blue.300'}}>
              <Icon w='32px' h='32px' as={BsBookmarkPlusFill} />
            </Button>
          }
          name='Reserva'
          value='Crear'
          style={{cursor: 'pointer'}}
          click={
            () => setOpenModalCreateBooking(true)
          }
        />
        {/* <MiniStatistics
          endContent={
            <Button
              w='56px'
              h='56px'
              bg="blue.500"
              color="white"
              variant='outline'
              borderRadius='50%'
              _hover={{bg: 'blue.300'}}
              >
              <Icon w='32px' h='32px' as={AiOutlinePlus} />
            </Button>
          }
          name='Contenedor'
          value='Reserva'
          style={{cursor: 'pointer'}}
          click={
            console.log('click')
          }
        />*/}
        </SimpleGrid> 


      <Grid templateColumns='repeat(12, 1fr)' gap='20px' mb='20px'>
        <GridItem colSpan={{ base: 12, md: 12, lg: 7, xl: 7 }}>
          <WeeklyRevenue 
            user={user}
            bookings={bookings}
            updateData={updateData}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 12, lg: 5, xl: 5 }}>
          <MonthCalendar bookings={bookings} />
        </GridItem>
        {/* <GridItem colSpan={{ base: 12, md: 12, lg: 12, xl: 3 }}>
          <MiniCalendar />
        </GridItem> */}

      </Grid>
      <Grid templateColumns='repeat(12, 1fr)' gap='20px' mb='20px'>
        <GridItem colSpan={{ base: 12, md: 12, lg: 12, xl: 12 }}>
          <BookingTable 
            setAlert={setAlert}
            bookings={bookings}
            setBookings={setBookings}
            setUpdateData={setUpdateData}
            loading={loading}
          />
        </GridItem>
      </Grid>
      {alert.show && <Alerts open={alert.show} setAlert={setAlert} status={alert.status} message={alert.message} />}
      <ModalCreateBooking 
        user={user}
        open={openModalCreateBooking} 
        setOpen={setOpenModalCreateBooking}
        setAlert={setAlert}
        setUpdateData={setUpdateData}
      />
    </Box>
  );
}
