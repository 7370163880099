import React, {  useEffect, useState } from 'react'
import { Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, SimpleGrid, FormLabel, Select} from '@chakra-ui/react'
import axios from 'axios';
import { constants } from 'Constants';
import moment from 'moment/moment';

export const ModalEditBooking = ({open, setOpen, booking, setUpdateData, setAlert}) => {

    const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

    const onClose = () => setOpen(false)
    const [editBooking, setEditBooking] = useState()
    useEffect(() => {
        setEditBooking(booking)
    }, [booking])


    const handleEditCustomer = async () => {
        try {
            const response = await axios.put(constants.url + "bookings/edit/" + editBooking._id, editBooking)
            setUpdateData(true)
            setAlert({
                show: true,
                status: "success",
                message: response.data.message
            })
            setOpen(false)
        } catch (error) {
            console.log(error)
            setAlert({
                show: true,
                status: "error",
                message: error.response.data ? error.response.data.message : "Error al editar"
            })
        }
    }


    return (
        <div>
            {editBooking && <Modal
                size='xl'
                isOpen={open}
                onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Editar Reserva
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleGrid columns={2} spacing={4}>
                            <FormControl id="id">
                                <FormLabel>Localizador</FormLabel>
                                <Input
                                    type="text"
                                    value={editBooking.id}
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, id: e.target.value })
                                    }
                                />
                            </FormControl>
                            {/* <FormControl id="date_booking">
                                <FormLabel>Fecha de Reserva</FormLabel>
                                <Input
                                    value={moment(editBooking.date_booking).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, date_booking: e.target.value })
                                    }
                                />
                            </FormControl> */}
                            <FormControl id="date_arrival">
                                <FormLabel>Fecha de Llegada</FormLabel>
                                <Input
                                    value={moment(editBooking.date_arrival).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, date_arrival: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl id="date_departure">
                                <FormLabel>Fecha de Salida</FormLabel>
                                <Input
                                    value={moment(editBooking.date_departure).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, date_departure: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl id="status">
                                <FormLabel>Estado</FormLabel>
                                <Select 
                                    value={editBooking.status}
                                    color={textColor}
                                    placeholder="Selecciona una opción"
                                    onChange={(e) => setEditBooking({ ...editBooking, status: e.target.value })}>
                                    <option value="pendiente">Pendiente</option>
                                    <option value="confirmada">Confirmada</option>
                                    <option value="cancelada">Cancelada</option>
                                </Select>
                            </FormControl>
                            {editBooking.status !== "pendiente" && (
                                <FormControl id="name_of_guest">
                                    <FormLabel>Nombre del huésped</FormLabel>
                                    <Input
                                        value={editBooking.name_of_guest}
                                        type="text"
                                        color={textColor}
                                        onChange={(e) =>
                                            setEditBooking({ ...editBooking, name_of_guest: e.target.value })
                                        }
                                    />
                                </FormControl>
                            )}
                            <FormControl id="adults">
                                <FormLabel>Adultos</FormLabel>
                                <Input
                                    value={editBooking.adults}
                                    type="number"
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, adults: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl id="children">
                                <FormLabel>Niños</FormLabel>
                                <Input
                                    value={editBooking.children}
                                    type="number"
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, children: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl id="babies">
                                <FormLabel>Bebés</FormLabel>
                                <Input
                                    value={editBooking.babies}
                                    type="number"
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, babies: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl id="house">
                                <FormLabel>Casa</FormLabel>
                                <Input
                                    value={editBooking.house}
                                    type="text"
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, house: e.target.value })
                                    }
                                />
                            </FormControl>
                            <FormControl id="price">
                                <FormLabel>Precio</FormLabel>
                                <Input
                                    value={editBooking.price}
                                    type="number"
                                    color={textColor}
                                    onChange={(e) =>
                                        setEditBooking({ ...editBooking, price: e.target.value })
                                    }
                                />
                            </FormControl>
                        </SimpleGrid>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost'
                        mr={3}
                            onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button colorScheme='blue' 
                            onClick={
                                () => {
                                    handleEditCustomer()
                                    onClose()
                                }
                            }>
                            Editar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>}
        </div>
    )
}
