import React, { useState } from 'react'
import { Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, SimpleGrid, FormLabel, Select} from '@chakra-ui/react'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import axios from 'axios'
import { constants } from 'Constants'

export const ModalAddPhone = ({open, setOpen, booking, setUpdateData, setAlert}) => {

    const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

    const onClose = () => setOpen(false)
    const initialRef = React.useRef()
    const finalRef = React.useRef()

    const [editBooking, setEditBooking] = useState()

    const handleEditCustomer = async () => {
        try {
            const response = await axios.put(constants.url + "bookings/edit/" + editBooking._id, editBooking)
            setUpdateData(true)
            setAlert({
                show: true,
                status: "success",
                message: 'Teléfono añadido correctamente'
            })
            setOpen(false)
        } catch (error) {
            console.log(error)
            setAlert({
                show: true,
                status: "error",
                message: error.response.data ? error.response.data.message : "Error al editar"
            })
        }
    }

    const handleAddPhone = (e) => {
        setEditBooking({...booking, phone: e})
    }

  return (
    <div>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={open}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Añadir teléfono
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Móvil</FormLabel>
                <PhoneInput 
                    defaultCountry="GB" 
                    placeholder="Introduzca el teléfono"
                    value={booking.phone}
                    onChange={handleAddPhone}
                    style={
                        {
                            width: '100%',
                            height: 'calc(1.5em + 1.25rem + 2px)',
                            border: '1px solid #E2E8F0',
                            borderRadius: '0.375rem',
                            padding: '0.5rem 1rem',
                            color: '#4A5568',
                            backgroundColor: '#fff',
                            backgroundImage: 'none',
                            appearance: 'none', 
                            transition: 'box-shadow 0.15s ease',
                            fontSize: '1rem',
                            lineHeight: '1.5',
                            outline: 'none',
                            boxShadow: 'none',
                            _hover: {
                                borderColor: '#CBD5E0',
                                boxShadow: 'none',
                            },
                            _focus: {
                                borderColor: '#CBD5E0',
                                boxShadow: 'none',
                            },
                            _active: {
                                borderColor: '#CBD5E0',
                                boxShadow: 'none',
                            },
                        }
                    }
                />

            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button  mr={3} onClick={onClose}>Cancelar</Button>
            <Button colorScheme='blue' onClick={handleEditCustomer}>
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
                    
    </div>
  )
}
