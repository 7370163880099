import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/sass/styles.scss';
import "assets/css/MiniCalendar.css";

import { Text, Icon, Button, useColorModeValue, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, PopoverFooter, Tooltip, Flex, useDisclosure, useToast } from "@chakra-ui/react";
// Chakra imports
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
// Custom components
import Card from "components/card/Card.js";
import moment from "moment";
import 'moment/locale/es';
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";

export default function MonthCalendar(props) {
  const {bookings, ...rest } = props;

  const localizer = momentLocalizer(moment);
  const history = useNavigate();

  const [events, setEvents] = useState([])

  const [openPopover, setOpenPopover] = useState(false);
  const onOpen = () => setOpenPopover(true);
    const onClose = () => setOpenPopover(false);

    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);

  useEffect(() => {
    const eventsParse = bookings && bookings.data.map((item) => {
      return {
        _id: item._id,
        localizador: item.id,
        nombre_viajero: item.name_of_guest,
        randomColor: randomColor,
        start: item.date_arrival ? new Date(item.date_arrival) : null ,
        end: item.date_departure ? new Date(item.date_departure) : null,
        price: item.price,
        title: " ",
        color: item.status === "confirmada"
            ? " #01b574"
            : item.status=== "pendiente"
            ? "#ffb547"
            : null,
      };
    });
    setEvents(eventsParse);
  }, [bookings]);

  const handleEventSelection = (e) => {
    setOpenPopover(true);
  }
    
  const handleSelectSlot = (e) => {  
     
  }
  const eventStyleGetter = (e) => {
    return {
        style: {
            backgroundColor: e.color,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        }
    }
  }
  return (
    <Card
      align='center'
      direction='column'
      w={{ base: "100%", md: "100%", xl: "600px" }}
      maxW='max-content'
      p='20px 15px'
      h='max-content'
      {...rest}>

      <Calendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 300, width: '500px', maxWidth: '100%' }}
          events={events}
          defaultView="month"
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleEventSelection} 
          onSelectSlot={handleSelectSlot}
          popup
          selectable
          messages={{
              month: 'Mes',
              day: 'Días',
              today: 'Hoy',
              week: 'Semana',
              previous: "Atras",
              back: 'Atrás',
              next: 'Siguiente',
              noEventsInRange: 'No hay eventos en este rango.',
              allDay: 'Todo el día',
              date: 'Fecha',
              time: 'Hora',
              event: 'Evento',
              work_week: 'Semana laboral',
              showMore: total => `+ Ver más (${total})`    
          }}
          views={['month', 'week']}
          components={{ toolbar: _props => <CustomToolbar handleSelectSlot={handleSelectSlot} {..._props}  />,
          event: _props => {
            return (
                <Tooltip label={_props.event.price + ' €'} placement="top" hasArrow zIndex={9999}>
                    <div style={{display: 'flex'}}>
                        <BsCircleFill color={_props.event.randomColor} style={{marginTop: '5px', marginRight: '5px'}}/>
                        <Text fontSize='md' isTruncated>{_props.event.nombre_viajero}</Text>
                    </div>
                </Tooltip>
            )
          }}
        }
      />
      {/* <Calendar
      tileClassName={({ date, view }) => {
        if(value.find(x=>x===moment(date).format("DD/MM/YYYY"))){
        return  'highlight'
        }
      }}
        onChange={onChange}
        value={new Date()}
        selectRange={true}
        view={"month"}
        disabled={true}
        selectMultiple={true}
        tileContent={<Text color='brand.500'></Text>}
        prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
        nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
      /> */}
    </Card>
  );
}
const CustomToolbar = (toolbar) => {

  const goToBack = () => {
      toolbar.onNavigate('prev');
      if (toolbar.view === 'month'){
          toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (toolbar.view === 'week'){
          toolbar.date.setDate(toolbar.date.getDate() - 7);
      } 
  };

  const goToNext = () => {
      toolbar.onNavigate('next');
      if (toolbar.view === 'month'){
          toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (toolbar.view === 'week'){
          toolbar.date.setDate(toolbar.date.getDate() + 7);
      }
  };

  const goToCurrent = () => {
      const today = new Date();
      toolbar.onNavigate('current', today);
  };

  const monthView = document.getElementsByClassName('rbc-months-view')
  const weekView = document.getElementsByClassName('rbc-week-view')

  const goToMonth = () => {
      toolbar.onView('month');
      monthView[0].classList.add('active')
      weekView[0].classList.remove('active')
  };

  const goToWeek = () => {
      toolbar.onView('week');
      weekView[0].classList.add('active')
      monthView[0].classList.remove('active')
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
      <div className="rbc-toolbar">
          <Text className="rbc-btn-group" color={textColor}>
              <Button size="xs" colorScheme={textColor} onClick={goToBack}><ChevronLeftIcon /> </Button>
              <Button size="xs" colorScheme={textColor} onClick={goToCurrent}>Hoy</Button>
              <Button size="xs" colorScheme={textColor} onClick={goToNext}> <ChevronRightIcon /></Button>
          </Text>
          <Text className="rbc-toolbar-label" color={textColor}>{toolbar.label}</Text>

          {/* <span className="rbc-btn-group">
              <button type="button" className="rbc-week-view" onClick={goToWeek}>Semana</button>
              <Button type="button" className="rbc-week-view rbc-toolbar active" onClick={goToWeek}>Semana</Button>
              <Button type="button" className="rbc-months-view rbc-toolbar" onClick={goToMonth}>Mes</Button>
          </span> */}
      </div>
  );
}
const CustomPopover = (props) => {
    const { isOpen, onOpen, onClose } = props;
    return (
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="bottom" closeOnBlur={false}>
        <PopoverTrigger>
          <Button>Open</Button>
        </PopoverTrigger>
        <PopoverContent p={5}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Popover Header</PopoverHeader>
          <PopoverBody>dqqwdwqdwdw</PopoverBody>
          <PopoverFooter>This is the footer</PopoverFooter>
        </PopoverContent>
      </Popover>
    );
  };