import React, { useEffect, useState } from 'react'
import { Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, SimpleGrid, FormLabel, Select, Flex, Image, Textarea } from '@chakra-ui/react'
import { Select as ChakraSelect } from 'chakra-react-select'
// import from assets folder img flags 
import es from '../../../../assets/img/flags/es.png'
import gb from '../../../../assets/img/flags/gb.png'
import moment from 'moment'
export const ModalSendMessage = ({ open, setOpen, booking, setUpdateData, setAlert }) => {
    const onClose = () => setOpen(false);
    const initialRef = React.useRef();
    const finalRef = React.useRef();

    const user = JSON.parse(localStorage.getItem('user'));

    const [selectLanguage, setSelectLanguage] = useState('es');
    const [selectMessage, setSelectMessage] = useState('');
    const [selectMessagePreview, setSelectMessagePreview] = useState('');

    const languages = [
        { value: 'es', label: 'Español', flag: es },
        { value: 'gb', label: 'English', flag: gb },
    ];

    const handleLanguageChange = (value) => {
        setSelectLanguage(value);
    };

    const handleSendMessage = () => {
        window.open(
            `https://api.whatsapp.com/send?phone=${booking.phone}&text=${selectMessage}`,
            '_blank'
        );
        onClose();

    };

    const handlePreviewMessage = (message) => {
        setSelectMessagePreview(message);
        setSelectMessage(message);
    };

    useEffect(() => {
        setSelectMessage('');
        setSelectMessagePreview('');
    }, [open]);


    const customOption = ({ innerProps, data }) => {
        return (
            <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={data.flag} alt={data.value} style={{ marginRight: '0.5rem', width: '20px' }} />
                {data.label}
            </div>
        );
    };

    return (
        <div>
            <Modal size={'xl'} initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={open} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Envío de Whatsapp</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Idioma</FormLabel>
                            <ChakraSelect
                                options={languages}
                                isSearchable={false}
                                components={{ Option: customOption }}
                                defaultValue={languages[0]}
                                onChange={(selectedOption) => handleLanguageChange(selectedOption.value)}
                            />
                            <FormLabel mt={4}>¿Qué mensaje le quieres enviar?</FormLabel>
                            <Flex>
                                <Select placeholder="Selecciona una opción"
                                    onChange={(e) => handlePreviewMessage(e.target.value)}>
                                    {messages(booking)
                                        .filter((message) => message.language === selectLanguage)
                                        .map((message) => (
                                            <option key={message.id} value={message.message}>
                                                {message.title}
                                            </option>
                                        ))}
                                </Select>

                                <Button colorScheme="gray" size="md" ml={2} onClick={() => setSelectMessage(selectMessagePreview)}>
                                    Vista previa
                                </Button>
                            </Flex>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Vista previa del mensaje</FormLabel>
                            <Textarea
                                value={selectMessagePreview}
                                onChange={(e) => setSelectMessagePreview(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button colorScheme="blue" onClick={handleSendMessage} disabled={
                            selectMessagePreview === ''
                        }>
                            Guardar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

const messages = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const messages = [
        {
            id: 1,
            title: 'Mensaje de bienvenida',
            message: `Hola ${props.name_of_guest}, soy ${user.name} de ${props.house}, te escribo para confirmar tu reserva para el día ${moment(props.date_arrival).format('DD/MM/YYYY')}. Recuerda que debes traer la fianza de 500,00€ en efectivo. Si tienes cualquier duda, puedes contactar conmigo en este mismo número. Que tengas un buen viaje. Un saludo. ${user.name}`,
            language: 'es',
        },
        {
            id: 2,
            title: 'Mensaje de bienvenida',
            message: `Hello ${props.name_of_guest}, I am ${user.name} from ${props.house}, I am writing to confirm your reservation for ${moment(props.date_arrival).format('DD/MM/YYYY')}. Remember that you must bring the deposit of € 500.00 in cash. If you have any questions, you can contact me at this number. Have a good trip. Best regards. ${user.name}`,
            language: 'gb',
        },
        {
            id: 3,
            title: 'Mensaje de despedida',
            message: `¡Hola ${props.name_of_guest}! Queríamos aprovechar esta oportunidad para agradecerte por elegir ${props.house} durante tu estancia. Ha sido un placer tenerte como inquilino y queremos expresar nuestro agradecimiento por cuidar de la casa durante este tiempo. Si tienes algún comentario o sugerencia sobre tu experiencia, nos encantaría escucharlo. ¡Nos sería de gran ayuda si pudieras dejarnos una buena reseña para futuros huéspedes! Esperamos que hayas tenido una estancia maravillosa y te deseamos lo mejor. ¡Hasta la próxima!`,
            language: 'es',
        },
        {
            id: 4,
            title: 'Mensaje de despedida ',
            message: `Hello ${props.name_of_guest}! We wanted to take this opportunity to thank you for choosing our house during your stay. It has been a pleasure having you as our tenant, and we want to express our appreciation for taking care of the house during this time. If you have any feedback or suggestions about your experience, we would love to hear it. It would be greatly appreciated if you could leave us a positive review for future guests! We hope you had a wonderful stay and wish you all the best. Until next time!`,
            language: 'gb',
        }

    ];

    return messages;
};