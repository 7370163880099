import React, {  useEffect, useState } from 'react'
import { Button, FormControl, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue } from '@chakra-ui/react'
import axios from 'axios';
import { constants } from 'Constants';

export const ModalEditCustomer = ({customer, customers, setIsEdit, open, setOpen, setAlert}) => {

    const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

    const onClose = () => setOpen(false)
    const [editCustomer, setEditCustomer] = useState({})

    useEffect(() => {
        const foundCustomer = customers.find(c => c._id === customer._id);
        if (foundCustomer) {
            setEditCustomer(foundCustomer);
        }
    }, [customer, customers]);

    const handleEditCustomer = async () => {
        try {
            const response = await axios.put(`${constants.url}customers/edit/${customer._id}`, editCustomer)
            setIsEdit(true)
            if (response.data.ok) {
                onClose()
                setAlert({
                    show: true,
                    status: "success",
                    message: `Cliente ${editCustomer.name} editado correctamente`
                })
            }

        } catch (error) {
            console.log(error)
            setAlert({
                show: true,
                status: "error",
                message: "Error al editar el cliente" + error.response?.data?.message
            })
        }
    }

    

    return (
        <div>
            <Modal isOpen={open} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>
                    Editar cliente
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <FormControl>
                        <label>Nombre</label>
                        <Input 
                            type='text'
                            name='name'
                            color={textColor}
                            value={editCustomer.name}
                            onChange={(e) => setEditCustomer({...editCustomer, [e.target.name]: e.target.value.toUpperCase()})}
                        />
                    </FormControl>

                    <FormControl mt={4}>
                        <label>Telefono</label>
                        <Input
                            type='text'
                            name='phone'
                            color={textColor}
                            value={editCustomer.phone}
                            maxLength={9}
                            minLength={9}
                            onChange={(e) => setEditCustomer({...editCustomer, [e.target.name]: e.target.value})}
                        />
                    </FormControl>
                    
                </ModalBody>

                <ModalFooter>
                    <Button variant='ghost' onClick={onClose}>
                        Cerrar
                    </Button>
                    <Button colorScheme='blue' ml={3} onClick={handleEditCustomer} isDisabled={editCustomer.name === '' || editCustomer.phone === ''}>
                        Editar
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}
