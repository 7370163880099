import React from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, Text } from '@chakra-ui/react'
import axios from 'axios';
import { constants } from 'Constants';
export const ModalDeleteBooking = ({open, setOpen, booking, setUpdateData, setAlert}) => {

    const onClose = () => setOpen(false);

    const handleDelete = async () => {
        try {
            const response = await axios.delete(constants.url + "bookings/delete/" + booking._id)
            setUpdateData(true)
            setAlert({
                show: true,
                status: "success",
                message: response.data.message
            })
            onClose()
        } catch (error) {
            console.log(error)
            setAlert({
                show: true,
                status: "error",
                message: error.response.data ? error.response.data.message : "Error al eliminar"
            })
        }
    }

  return (
    <>


    <AlertDialog
      isOpen={open}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Eliminar reserva
          </AlertDialogHeader>

          <AlertDialogBody>
          <Text fontSize='md' mb='2'>
            ¿Seguro que desea eliminar la reserva de <b>{booking?.name_of_guest}</b> de <b>{booking?.house}</b>?
            </Text>
            <br />
            <br />
            Esta acción no se puede deshacer.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme='red' onClick={handleDelete} ml={3}>
              Eliminar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  </>
  )
}
