import { Button, ButtonGroup, HStack } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const Pagination = ({ currentPage, totalPages, onPageChange}) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };


  return (
    <HStack mt={6} justify="center">
      <ButtonGroup size={"sm"} >
        <Button
          size="sm"
          colorScheme="blue"
          disabled={isFirstPage}
          onClick={() => handlePageChange(currentPage - 1)}
          leftIcon={<ChevronLeftIcon />}
        >
        </Button>
        {currentPage > 2 && (
          <Button size="sm" onClick={() => handlePageChange(1)}>{1}</Button>
        )}
        {currentPage > 3 && <Button disabled>...</Button>}
        {currentPage > 1 && (
          <Button size="sm" onClick={() => handlePageChange(currentPage - 1)}>
            {currentPage - 1}
          </Button>
        )}
        <Button size="sm" colorScheme="blue" >
          {currentPage}
        </Button>
        {currentPage < totalPages && (
          <Button size="sm" onClick={() => handlePageChange(currentPage + 1)}>
            {currentPage + 1}
          </Button>
        )}
        {currentPage < totalPages - 2 && <Button disabled>...</Button>}
        {currentPage < totalPages - 1 && (
          <Button size="sm" onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </Button>
        )}
        <Button
          size="sm"
          colorScheme="blue"
          disabled={isLastPage}
          onClick={() => handlePageChange(currentPage + 1)}
          rightIcon={<ChevronRightIcon />}
        >
          
        </Button>
      </ButtonGroup>
    </HStack>
  );
};

export default Pagination;
